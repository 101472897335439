import React from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import SendMoney from './SendMoney';
import RequestMoney from './RequestMoney';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* Sidebar */}
        <div className="col-md-2 p-0 vh-100 bg-dark text-white">
          <div className="d-flex flex-column h-100">
            {/* Logo */}
            <div className="p-3 text-center">
              <img src="https://signup.faster.ca/logo.png" alt="Logo" style={{ width: '80px' }} />
            </div>

            {/* Menu */}
            <ul className="nav flex-column mt-4">
              <li className="nav-item">
                <Link to="send-money" className="nav-link text-white">
                  Send Money
                </Link>
              </li>
              <li className="nav-item">
                <Link to="request-money" className="nav-link text-white">
                  Request Money
                </Link>
              </li>
            </ul>

            {/* Logout button at the bottom */}
            <div className="mt-auto p-3">
              <button className="btn btn-danger w-100" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>

        {/* Main content */}
        <div className="col-md-10">
          <Routes>
            {/* Nested Routes */}
            <Route path="send-money" element={<SendMoney />} />
            <Route path="request-money" element={<RequestMoney />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;