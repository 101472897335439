import React, { useEffect, useState } from 'react';
import { getEtransfers } from '../services/api';

const SendMoney = () => {
  const [etransfers, setEtransfers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1); // Page number state
  const [limit, setLimit] = useState(10); // Items per page
  const [from, setFrom] = useState(''); // From date
  const [to, setTo] = useState(''); // To date
  const [totalPages, setTotalPages] = useState(1); // Total pages from API

  // Fetch e-transfers only when token is available
  const token = localStorage.getItem('token'); // Fetch token directly from localStorage

  useEffect(() => {
    if (!token) {
      setError('No token found. Please login.');
      return;
    }

    const fetchEtransfers = async () => {
      setLoading(true);
      try {
        const data = await getEtransfers({ page, limit, from, to });
        setEtransfers(data.data); // Assuming the API returns { data: [...], total_pages: X }
        setTotalPages(data.total_pages); // Set total pages based on API response
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch e-transfers');
        setLoading(false);
      }
    };

    fetchEtransfers();
  }, [page, limit, from, to, token]); // Re-fetch when page, limit, date filters, or token changes

  const handleDateFilter = (e) => {
    e.preventDefault();
    setPage(1); // Reset to page 1 when filters are applied
    // The useEffect hook will automatically refetch with the new date filters
  };

  if (loading) {
    return <p className="text-center">Loading...</p>;
  }

  if (error) {
    return <p className="text-danger text-center">{error}</p>;
  }

  return (
    <div>
        <br />
      <h2 className="text-center mb-4">Send Money</h2>

      {/* Date Filters */}
      <form onSubmit={handleDateFilter} className="mb-4">
        <div className="row">
          <div className="col-md-3">
            <label>From</label>
            <input
              type="date"
              className="form-control"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <label>To</label>
            <input
              type="date"
              className="form-control"
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
          <div className="col-md-2 d-flex align-items-end">
            <button type="submit" className="btn btn-primary w-100">Filter</button>
          </div>
        </div>
      </form>

      {/* Table of E-transfers */}
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead className="table-dark">
            <tr>
              <th scope="col">E-transfer ID</th>
              <th scope="col">Amount</th>
              <th scope="col">Receiver</th>
              <th scope="col">Status</th>
              <th scope="col">Created At</th>
            </tr>
          </thead>
          <tbody>
            {etransfers.map((transfer) => (
              <tr key={transfer.etransfer_id}>
                <td>{transfer.etransfer_id}</td>
                <td>${transfer.amount}</td>
                <td>{transfer.receiver_email}</td>
                <td>
                  <span className={`badge bg-${transfer.status === 'received' ? 'success' : 'warning'} text-light`}>
                    {transfer.status}
                  </span>
                </td>
                <td>{new Date(transfer.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center mt-4">
        <div>
          <button
            className="btn btn-secondary"
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >
            Previous
          </button>
        </div>
        <span>Page {page} of {totalPages}</span>
        <div>
          <button
            className="btn btn-secondary"
            onClick={() => setPage(page + 1)}
            disabled={page === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendMoney;