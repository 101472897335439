import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/api';
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = ({ setToken }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await login(email, password);
      localStorage.setItem('token', data.token); // Save token in localStorage
      setToken(data.token); // Also set in the state
      navigate('/dashboard/send-money');
    } catch (err) {
      setError('Invalid login credentials');
    }
  };

  return (
    <div
      style={{
        background: 'linear-gradient(135deg, #ff8038, #ffb347)',  // Gradient background
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* Login Box */}
      <div
        className="login-box"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slight transparency for background
          padding: '40px',
          borderRadius: '10px',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)', // Subtle shadow
          maxWidth: '400px',
          width: '100%',
          transition: 'transform 0.3s ease', // Animation effect
          transform: 'scale(1)',
        }}
      >
        {/* Logo */}
        <div className="text-center mb-4">
          <img
            src="https://cdn.prod.website-files.com/65b88ec507a48a8fcbb6f28c/65c3c2286b0af766d5f7f88d_Logo_OrangeBlack_Faster-p-500.png"
            alt="Logo"
            style={{ width: '150px' }}
          />
        </div>

        <h4 className="text-center mb-4">Login</h4>

        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="text-danger">{error}</p>}
          <button type="submit" className="btn btn-primary w-100">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;