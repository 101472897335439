import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { setAuthToken } from './services/api';

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setAuthToken(storedToken);
      setToken(storedToken);
    }
    setLoading(false);
  }, []);

  const handleSetToken = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
    setAuthToken(token);
  };

  if (loading) {
    return <p className="text-center">Loading...</p>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login setToken={handleSetToken} />} />
        {/* Use Dashboard as a parent route */}
        <Route path="/dashboard/*" element={<Dashboard />} />
      </Routes>
    </Router>
  );
};

export default App;